/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen {
  .print-table-header-group {
    display: none;
  }

  .watermark {
    display: none;
  }

  .print-table-footer-group {
    display: none;
  }

  .page-header-left {
    display: none;
  }

  .page-header-right {
    display: none;
  }

  .page-footer-left {
    display: none;
  }

  .page-footer-right {
    display: none;
  }

  #page-footer-page-number {
    display: none;
  }
}

@media print {

  @page {
    /* Keep the aspect ratio the same as A4 */
    /* Leave 10% margin at all ends */
    /* Print using screen size lg */
    /* margin: 128px; */
    /* size: 1536px 2172px */
    /* Print using screen size md */
    margin: 0 0 0 0;
    size: 1280px 1810px;
  }

  /**
   * https: //stackoverflow.com/questions/907680/css-printing-avoiding-cut-in-half-divs-between-pages
   */
  div {
    /* page-break-inside: avoid; */
    /* orphans: 10;
    widows: 10; */
    /* break-inside: avoid; */
  }

  html,
  body {
    /* padding-top: 38px; */
    margin: 0px;
    overflow: visible;
  }

  /* 15px Left Border for Printing */
  .page-border {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 20px solid #ff6d00cc;
  }

  /* Watermark at the center of the Page for Printing */
  .watermark {
    position: fixed;
    top: 50%;
    transform: rotateY(0) rotate(315deg);
    width: 100%;
    text-align: center;
    font-size: 36pt;
    color: rgba(200, 200, 200, 0.3);
  }

  /* Override <Grid container> to display:block for Printing */
  .print-container {
    display: block !important;
  }

  .print-table {
    display: table !important;
    table-layout: fixed;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  /* Print Header */
  .print-table-header-group {
    display: table-header-group;
  }

  .print-table-header-cell {
    display: table-cell;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 0px;
    text-align: left;
    height: 100px;
  }

  /* Print Footer */
  .print-table-footer-group {
    display: table-footer-group;
    vertical-align: middle;
  }

  .print-table-footer-cell {
    display: table-cell;
    width: 100%;
    text-align: center;
    height: 100px;
    font-size: 12px;
  }

  /* Print Content */
  .print-table-row-group {
    display: table-row-group;
    padding-left: 20px;
  }

  .print-table-row {
    display: table-row;
  }

  .print-table-cell {
    display: table-cell;
    width: 100%;
    padding: 0 60px;
  }

  .page-header-left {
    position: fixed;
    top: 20px;
    left: 30px;
  }

  .page-header-right {
    position: fixed;
    top: 20px;
    right: 30px;
  }

  .page-footer-left {
    position: fixed;
    bottom: 50px;
    left: 50px;
  }

  .page-footer-right {
    position: fixed;
    bottom: 50px;
    right: 50px;
  }

  /* Not working for now */
  #page-footer-page-number::after {
    display: none;
    position: fixed;
    bottom: 50px;
    left: 50%;
    counter-increment: page;
    content: 'Page ' counter(page) ' of ' counter(pages);
  }

  /* Print Header and Footer utimate solution: https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a */
}