/* @font-face {
  font-family: 'Kai';
  src: url(/fonts/cwTeXQKai-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Kai';
  src: url(/fonts/cwTeXQKai-Bold.ttf) format('truetype');
  font-weight: bold;
} */

/* https: //github.com/hepochen/fonts/tree/master */
@font-face {
  font-family: 'Wang-Kai';
  src: url(/fonts/wt064.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Wang-Kai';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Slate  */
blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir='rtl'] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}